export const UIPaths =  {
  login: '/login',
  loginPrefilled: '/login/:prefilled',
  register: {
    step1: '/signup',
    step1WithCustomerCode: '/signup/:customerCode?',
    referralSignup: '/referral-signup/:checkoutSessionID'
  },
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',//'/reset-password/:magicToken?',
  dashboard: '/dashboard/home',
  // matomoAnalytics: '/store-analytics',
  howTo: {
    list: '/how-to',
    detail: '/how-to/:guideCode',
  },
  unAuthorized: '/misc/not-authorized',
  // attribute: {
  //   list: '/variation/list',
  //   detail: '/variation/detail/:attributeID',
  // },
  // productCategory: {
  //   list: '/product-category/list',
  //   detail: '/product-category/detail/:productCategoryID',
  //   metaData: '/product-category/metadata/:productCategoryID',
  // },
  product: {
    list: '/product/list',
    detail: '/product/detail/:productID',
    images: '/product/images/:productID',
    metaData: '/product/metadata/:productID',
    bulkUpload: '/product/bulkUpload',
  },
  user: {
    list: '/customer/list',
    addNew: '/customer/detail',
    detail: '/customer/detail/:emailID',
    bulkAddTag: '/customer/segementation-add-tag',
    bulkRemoveTag: '/customer/segementation-remove-tag',
  },
  userDashboardMgmt: {
    list: '/user/list',
    newUser:'/user/add', 
    detail: '/user/detail/:emailID',
  },
  store : {
    aboutStore: '/about-store',
    contactDetails: '/about-store/contact-details',
    license: '/about-store/manage-subscription',
    appSumoLicense: '/about-store/manage-appsumo-subscription',
    subscriptionPaymentSuccessful: '/subscription-successful',
    licenseDetails: '/about-store/plan-details',
    // subscriptionPaymentCancelled: '/about-store/subscription-payment-cancelled'
  },




  // --------------------------------------------

  entity: {
    list: '/entity/list',
    detail:'/entity/detail/:entityID',
    team:'/entity/team/:entityID',
    manageCompliances:'/entity/manage-compliances/:entityID',
    addCompliances: '/entity/add-compliances'
  },

  repo: {
    complianceList: '/repo/compliance/list',
    complianceWizard: '/repo/compliance/detail/:complianceID'
  },

  compliance: {
    categoryCardList: '/compliance/categories',
    listByCategory: '/compliance/categories/:categoryID',
  },

  event: {
    list: '/event/list',
    trigger:'/event/trigger',
    taskList:'/event-tasks/:entityEventID',
  },

  upcomingTask: {
    list: '/pending-tasks',
    detail: '/pending-tasks/:taskNumber',
  },

  allTask: {
    list: '/all-tasks',
    detail: '/all-tasks/:taskNumber',
  },

  complianceLeftMenu: {
    list: '/compliance/list',
    detail: '/compliance/detail'
  },

  alertBoard: {
    dashboard: '/alert-board'
  },
  cSuiteBoard: {
    dashboard: '/c-suite-summary'
  },
  auditor: {
    resourcesQuery: '/auditor-resources',
    resourcesResult: '/auditor-resources/result'
  },
  taskTracker: {
    report: '/task-tracker',
    detail: '/task-tracker/:taskNumber',
  },
  user: {
    list: '/user/list',
    detail: '/user/detail/:emailID',
    addNew: '/user/detail'
  },
  role: {
    list: '/role/list',
    detail: '/role/detail/:roleID',
    addNew: '/role/detail',

    access: '/role/access'
  },

  template: {
    designerView: '/template/designer'
  }

  // --------------------------------------------


  // shippingRate: {
  //   list: '/shipping-rate/list',
  //   detail: '/shipping-rate/detail',
  // },
  // shippingZone: {
  //   list: '/shipping-zone/list',
  //   detail: '/shipping-zone/detail',
  //   homeStateEdit: '/shipping-zone/update-home-state',
  // },
  // shiprocket: {
  //   detail: '/ship-rocket/setup',
  // },
  // order: {
  //   list: '/order/list',
  //   detail: '/order/detail/:userOrderID',
  //   viewInvoice: '/order/invoice/view/:userOrderID',
  //   printInvoice: '/order/invoice/print/:userOrderID',
  //   add: '/order/add',
  // },
  // cart: {
  //   list: '/cart/list',
  //   detail: '/cart/detail/:userOrderID',
  // },
  // discount: {
  //   list: '/discount/list',
  //   detail: '/discount/detail/:discountID',
  // },
  // role: {
  //   list: '/role/list',
  //   detail: '/role/detail/:roleID',
  // },
  // customerWebsiteContent: {
  //   preview: '/store-front/preview',
  //   edit:'/store-front/edit/:pageName',
  //   add:'/store-front/add'
  // },
  // storeDesigner: {
  //   preview: '/store-designer',
  //   // edit:'/store-designer/edit/:pageName',
  //   themes:'/themes',
  // },
  // paymentGateway: {
  //   detail: '/payment-gateway/detail',
  //   stripeSetup: '/payment-gateway/stripe-setup',// eventually change this to initialize-setup - when RazorPay setup is in place
  //   stripeReVerify: '/payment-gateway/stripe-reverify', // restart setup after stripe link has expired - url is used in stripe api
  //   razorpaySetup: '/payment-gateway/razorpay-setup',
  //   paypalSetup: '/payment-gateway/paypal-setup'
  // },
  // domain: {
  //   setup: '/domain/setup'
  // },
  // email: {
  //   configuration: '/email-configuration'
  // },
  // analytics: {
  //   setup: '/analytics-setup',
  //   externalScripts: '/external-scripts'
  // },
  // integration: {
  //   list: '/integrations',
  //   activate: '/integrations/activate/:shortCode'
  // },
  // apps: {
  //   list: '/apps',
  //   activate: '/apps/activate/:shortCode'
  // },
  // tools: {
  //   list: '/tools',
  //   activate: '/apps/activate/:shortCode'
  // },
  // seo: {
  //   report: '/seo-report'
  // },
  // metaField: {
  //   list: '/custom-fields',
  //   detail: '/custom-fields/detail/:metaFieldID',
  // },
  // googleFeed: {
  //   mapping: '/google-feed/mapping',
  //   preview: '/google-feed/preview',
  // },
  // mailchimp: {
  //   continueActivation: '/integrations/mailchimp/continue'
  // },

}

export const SPQueryStringParam = {
  lastModifiedOnDesc: '?q=lmo',
  addCategoryFromProductDetails: '?q=apd',
  restoreSearch: '?q=rsp',
  redirectToPage: '?q=rp-rpt',
  wooCommerceImportFromProductList: '?q=wc',
}
