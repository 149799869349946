const appMessages = {
  common_unhandled_exception_toast_heading: 'Couldn\'t Process',
  common_unhandled_exception: 'There was an error in processing your request',

  user_login_error_toast_heading: 'Login Failure',
  user_login_error: 'Invalid email or password',

  user_google_login_error_toast_heading: 'Login Failure',
  user_google_login_error: 'Google authentication failure',

  user_emailID_taken_toast_heading: 'Account exists for this user.',
  user_emailID_taken: 'There\'s already an account with this email. Perhaps you meant to login instead of register.',

  customer_emailID_taken_toast_heading: 'Email-id already taken.',
  customer_emailID_taken: 'A user with this email-id already exists.',

  user_authentication_failure_toast_heading: 'Session expired',
  user_authentication_failure: 'Login again to continue using the dashboard',

  password_used_previously_toast_heading: 'Password used previously',
  password_used_previously: 'New password cannot be same as old password',
  
  user_mark_inactive_confirmation_toast_heading: 'Are you sure?',
  user_mark_inactive_confirmation: 'Are you sure you want to disable this user? Once disabled, they will not be able to login',

  user_profile_email_already_exists_toast_heading: 'Duplicate email',
  user_profile_email_already_exists: 'A user with same email already exists. Cannot add',

  role_name_already_exists_toast_heading: 'Duplicate Role',
  role_name_already_exists: 'Role with that name already exists. Create a new role with a different name',

  role_delete_confirmation_toast_heading: 'Are you sure?',
  role_delete_confirmation: 'Are you sure you want to delete this role?',

  entity_save_duplicate_code_toast_heading: 'Duplicate Entity Code',
  entity_save_duplicate_code: 'Entity with same short code already exists. Add a different short code',

  entity_actwise_actor_save_toast_heading: 'Error assigning acts',
  entity_actwise_actor_save: 'There was an error when assigning acts',

  entity_actor_act_remove_confirmation_toast_heading: 'Are you sure?',
  entity_actor_act_remove_confirmation: 'This action will unassign <b>$1</b> from $2. Are you sure you want to continue?',

  entity_actor_act_remove_resource_not_found_toast_heading: 'Not Found',
  entity_actor_act_remove_resource_not_found: 'The compliance act that you are trying to remove could not be found. Try refreshing the page',

  entity_document_delete_confirmation_toast_heading: 'Are you sure?',
  entity_document_delete_confirmation: 'Are you sure you want to delete document - $1',

  compliance_bulk_add_no_row_selected_toast_heading: 'Select Compliances',
  compliance_bulk_add_no_row_selected: 'Select one or more compliances for adding',

  cannot_update_active_company_compliance_toast_heading: 'Cannot update published compliances',
  cannot_update_active_company_compliance: 'The selected compliance is published. Cannot updated',

  task_done_but_no_data_confirmation_toast_heading: 'Are you sure?',
  task_done_but_no_data_confirmation: 'This task has no data associated with it. Are you sure, you want to continue marking the task as done?',

  task_data_no_matching_record_found_toast_heading: 'Task data not found',
  task_data_no_matching_record_found: 'The data field $1 is no longer available for saving.',

  task_document_delete_confirmation_toast_heading: 'Are you sure?',
  task_document_delete_confirmation: 'Deleting a document is irreversible. Are you sure you want to continue with delete?',

  task_cancel_confirmation_toast_heading: 'Are you sure, you want to cancel this task?',
  task_cancel_confirmation: 'Cancelling a task is irreversible and it will make the task unavailable for any further actions on it. <br/>Are you sure, you want to continue with cancel action?',


  compliance_repo_make_inactive_confirmation_toast_heading: 'Are you sure?',
  compliance_repo_make_inactive_confirmation: 'Are you sure you want to make this compliance inactive?',

  compliance_repo_publish_confirmation_toast_heading: 'Are you sure?',
  compliance_repo_publish_confirmation: 'Are you sure you want to publish this compliance?',

  home_insights_no_tasks_done_0_toast_heading: 'New Beginnings Await!',
  home_insights_no_tasks_done_0: 'You\'re just getting started—take the first step towards strengthening compliance and making a real difference',

  home_insights_no_tasks_done_1_toast_heading: 'Compliance Starts with You!',
  home_insights_no_tasks_done_1: 'You haven\'t completed any compliance tasks yet. Each task you complete will bring the company closer to full compliance',

  home_insights_no_tasks_done_2_toast_heading: 'Time to Ensure Compliance!',
  home_insights_no_tasks_done_2: 'No tasks completed in the past 6 months—your efforts can help keep the company compliant. Let\'s start driving results today!',

  home_insights_no_tasks_done_3_toast_heading: 'Unlock Compliance Success!',
  home_insights_no_tasks_done_3: 'No tasks done in the last 6 months. Now\'s your chance to make an impact and protect the company from compliance risks!!',

  home_insights_no_tasks_done_4_toast_heading: 'Get Ahead on Compliance!',
  home_insights_no_tasks_done_4: 'A clean slate means you can make a difference. Start today to ensure we meet every regulatory requirement!',

  home_insights_no_tasks_done_5_toast_heading: 'Your Compliance Journey Begins!',
  home_insights_no_tasks_done_5: 'No completed tasks so far. Every task you tackle helps the company avoid penalties and stay on top of regulations',

  home_insights_productive_day_0_toast_heading: 'Compliance Champion in Action!',
  home_insights_productive_day_0: 'Your productivity on $0 was crucial in helping us meet compliance deadlines ',

  home_insights_productive_day_1_toast_heading: 'Another Productive Step Towards Compliance!',
  home_insights_productive_day_1: '$0 was your most productive day, helping keep the company on track with its compliance goals!',

  home_insights_productive_day_2_toast_heading: 'Leading the Way in Compliance!',
  home_insights_productive_day_2: 'You made the biggest impact on $0, bringing us closer to full compliance!',

  home_insights_productive_day_3_toast_heading: 'Compliance Achievements Unlocked!',
  home_insights_productive_day_3: '$0 was your standout day for task actions, pushing compliance efforts forward!',

  home_insights_productive_day_4_toast_heading: 'Driving Compliance Success!',
  home_insights_productive_day_4: 'Your efforts on $0 made a significant difference in keeping us aligned with regulatory requirements',

  home_insights_productive_day_5_toast_heading: 'Stepping Up for Compliance!',
  home_insights_productive_day_5: '$0 saw your highest productivity, helping safeguard the company from compliance risks!',

  home_insights_productive_month_0_toast_heading: "Outstanding Compliance Progress in $0!",
  home_insights_productive_month_0: "In $0, your efforts significantly advanced compliance initiatives and kept the company on track!",

  home_insights_productive_month_1_toast_heading: "Shaping Compliance Success in $0!",
  home_insights_productive_month_1: "Your contributions in $0 played a vital role in ensuring company's regulatory requirements were met!",

  home_insights_productive_month_2_toast_heading: "Driving Compliance Forward in $0! ",
  home_insights_productive_month_2: "Your achievements in $0 made a noticeable difference in compliance status!",

  home_insights_productive_month_3_toast_heading: "$0, Month of Compliance Triumphs! ",
  home_insights_productive_month_3: "You made significant strides in $0, helping the company stay aligned with all necessary regulations!",

  home_insights_productive_month_4_toast_heading: "Your Compliance Impact This $0! ",
  home_insights_productive_month_4: "In $0, your hard work has been instrumental in mitigating compliance risks for the company!",

  home_insights_productive_month_5_toast_heading: "Elevating Compliance Standards in $0! ",
  home_insights_productive_month_5: "Your contributions in $0 have strengthened the overall compliance framework and effectiveness!",

  home_insights_action_count_0_toast_heading: "Building Compliance Momentum!",
  home_insights_action_count_0: "Your $0 actions are crucial in ensuring we meet all compliance requirements effectively!",

  home_insights_action_count_1_toast_heading: "Action-Taking Champion! ",
  home_insights_action_count_1: "You've initiated $0 actions this period, driving company's compliance efforts forward!",

  home_insights_action_count_2_toast_heading: "Proactive Compliance Advocate!",
  home_insights_action_count_2: "Your $0 actions show your commitment to keeping the company compliant and on track!",

  home_insights_action_count_3_toast_heading: "Steps Toward Compliance!",
  home_insights_action_count_3: "With $0 actions taken, you're making significant progress in the company's compliance journey!",

  home_insights_action_count_4_toast_heading: "Your Contributions Matter! ",
  home_insights_action_count_4: "You've taken $0 actions, helping safeguard the company’s compliance status!",

  home_insights_action_count_5_toast_heading: "Compliance Action Leader! ",
  home_insights_action_count_5: "With $0 actions initiated, you're paving the way for stronger compliance practices!",


}
export default appMessages 