// ** React Imports
import { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'


// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import { useEffect } from 'react'
import appUtils from '../../sp-utils/app.utils'
// import userMessagesFr from '@src/assets/data/locales/fr.json'

// ** Menu msg obj
const menuMessages = {
  en: { ...userMessagesEn },
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState('en')
  const [messages, setMessages] = useState(menuMessages['en'])

  // ** Switches Language
  const switchLanguage = customerID => {
    const lang = 'eq' + customerID
    
    try {
      import('@src/assets/data/locales/' + lang + '.json')
        .then(data => {
          console.log('i18 changing lang = ', data)
          setMessages({...menuMessages['en'], ...data})
        })
        .catch(response => {})
        ;
    } catch{}

    // setLocale(lang)
    
  }

  useEffect(() => {
    const customerID = appUtils.getLoggedInUserCustomerID()
    
    if (customerID > 0)
      switchLanguage(customerID)
     
  }, [])
  

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='en'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
