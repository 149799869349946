
const appConstants =  {
  
  spWSPublicBaseUrl: process.env.REACT_APP_API_BASE_URL_PUBLIC,
  spWSProtectedBaseUrl: process.env.REACT_APP_API_BASE_URL,

  spSuperAdminUrl: process.env.REACT_APP_API_SUPER_ADMIN_URL,


  spWSPaths: {
    users: {
      login: 'users/login',
      list: 'users/list',
      team: 'users/team',
      listByFilterType: 'users/listByFilterType',
      create: 'users/create',
      createAdminUser: 'users/addDashboardUser',
      update: 'users/update',
      details: 'users/userDetails/:emailID',
      delete: 'users/delete/:userID',
      activityList: 'user/activity/list',
      userRoleList: 'userRole/list',
      userRoleAssign: 'userRole/assign',
      userRoleUnassign: 'userRole/delete/:userRoleID',
      logout: 'users/logout',
      userActivityTimeline: 'userActivityTimeline/:userID',
      // batchUpdateUsers: 'users/batchUpdateUsers',
      // storeOwnerDetails: 'users/storeOwnerDetails',
      // forgotPassword: 'users/forgotPassword/:emailID',
      resetPassword: 'users/resetPassword'
    },
    userAddress: {
      list: 'users/address/list/:userID',
      save: 'users/address/save',
      details: 'address/details/:userAddressID',
      pincodeDetails: 'pincode/details/:pincodeID'
    },
    
    

    // ------------------- ecomp -start
    company: {
      list: 'entity/list',
      details: 'entity/details/:entityID', //'address/details/:userAddressID',
      save: 'entity/save',  
      recommendedAttributes: 'entity/recommendedAttributes/:countryID'
    },
    entityDocument: {
      list: 'entity/entityDocument/list',
      detail: 'entity/entityDocument/details/:entityDocumentID',
      save: 'entity/entityDocument/save',
      saveProperties: 'entity/entityDocument/saveProperties',
      delete: 'entity/entityDocument/delete/:entityDocumentID',
      storageUsed: 'entity/entityDocument/storage/details' 
    },
    entityCompliance: {
      list: 'entity/entityCompliance/list',
      all: 'entity/entityCompliance/all',
      bulkAdd: 'entity/entityCompliance/bulkAdd',
      details: 'entity/entityCompliance/details/:entityComplianceID',
      updateStatus: 'entity/entityCompliance/updateStatus'
    },
    entityActor: {
      list: 'entity/entityActor/list',
      details: 'entity/entityActor/details/:entityActorID',
      save: 'entity/entityActor/save',
      actList: 'entity/entityActwiseActor/findByActor/:entityActorID',
      actSave: 'entity/entityActwiseActor/save',
      actDelete: 'entity/entityActwiseActor/delete/:entityActwiseActorID',
      actWiseList: 'entity/entityActwiseActor/list',
    },
    entityWFStep: {
      list: 'company/complianceTask/list',
      details: 'entity/entityWorkflowStep/details/:entityWFStepID',
      save: 'entity/entityWorkflowStep/save',
    },
    common: {
      complianceCodeList: 'common/compliance/code/list/:codeGroupID',
      codeList: 'common/codes/list',
      activityList: 'common/activity/list',
      countryList: 'common/country/list'
    },
    compliance: {
      list: 'compliance/list',
      category: 'compliance/category'
    },
    repo: {
      save: 'compliance/save',
      token: 'public/token'
    },
    task: {
      list: 'task/upcoming',
      all: 'task/all',
      details: 'task/taskDetails/:taskNumber'
    },
    taskAction: {
      save: 'task/taskAction/save'
    },
    taskData: {
      save: 'task/taskData/save'
    },
    alertBoard: {
      total: 'dashboard/alert/tasks/count',
      overdue: {
        count: 'dashboard/alert/overdue/count',
        entity: {
          chart: 'dashboard/alert/overdue/entity/graph',
          list: 'dashboard/alert/overdue/entity/list'
        },

        actor: {
          chart: 'dashboard/alert/overdue/actor/graph',
          list: 'dashboard/alert/overdue/actor/list'
        },
      },
      nonCompliance: {
        count: 'dashboard/alert/non-compliance/count',
        entity: {
          chart: 'dashboard/alert/non-compliance/entity/graph',
          list: 'dashboard/alert/non-compliance/entity/list'
        },
      },
      partiallyComplied: {
        reason: {
          chart: 'dashboard/alert/partially-complied/reason/graph',
          list: 'dashboard/alert/partially-complied/reason/list'
        }
      },
      cancelled: 'dashboard/alert/cancelled/count',
      
    },
    reports: {
      taskTracker: 'reports/task/tracker',
      misFormatExport: 'reports/task/trackerExport',
      complianceList: 'reports/compliance/list'
    },
    homeDashboard: {
      loggedInUser: {
        wipTask: { count: 'dashboard/loggedInUser/wipTask/count'},
        completedTask: { count: 'dashboard/loggedInUser/completedTask/count'},
        dueTodayTask: { count: 'dashboard/loggedInUser/tasksDueToday/count'},
        nearingDueDateTask: {count: 'dashboard/loggedInUser/tasksNearingDueDate/count'},
        overDueTask: {count: 'dashboard/loggedInUser/overdue/count'},
        notCompliedTask: {count: 'dashboard/loggedInUser/nonCompliance/count'},
        averageTime: 'dashboard/loggedInUser/averageResponseTime',
      },
      taskAction: {
        graph:'dashboard/taskAction/graph'
      },
      
    },
    auditor: {
      autoComplete: 'auditor/autocomplete',
      queryResult: 'auditor/test'
    },
    notification: {
      list: 'dashboard/notification/list',
      updateStatus: 'dashboard/notification/updateStatus'
    },
    entityEvent: {
      list: 'entity/entityEvent/list',
      expectedTaskList: 'task/entityEvent/expectedTaskList',
      eventTaskList: 'task/entityEvent/taskList/:companyEventID',
      save: 'entity/entityEvent/save',
    },
    // ------------------- ecomp - end
    
    
    
    
    
    products: {
      list: 'product/list',
      all: 'product/all',
      save: 'product/save',
      bulkUpload: 'product/bulkUpload',
      bulkUpload_AS: 'product/bulkUploadAS',
      details: 'product/details/:productID',
      delete: 'product/delete/:productID'
    },
    
    roles: {
      list: 'roles/list',
      create: 'roles/create',
      update: 'roles/update',
      delete: 'roles/delete/:roleID',
      details: 'role/details/:roleID'
    },
    roleActivityAccess: {
      details: 'roleActivity/details',
      save: 'roleActivity/save',
    },
    
    registration: {
      prospectDetails: 'onboarding/customer/prospect/details/:customerCode',
      prospectSave: 'onboarding/customer/prospect/save',
      oauth: 'onboarding/customer/prospect/oauth',
      isEmailAvailable: 'onboarding/customer/prospect/checkIfAvailable/:contactEmailID',
      generateVerificationToken: 'onboarding/customer/prospect/generateVerificationToken',
      reGenerateVerificationToken: 'onboarding/customer/prospect/reGenerateVerificationToken',
      verifyToken: 'onboarding/customer/prospect/verifyToken',
      countryList: 'onboarding/common/country/list',
      stripeGuestCheckoutActivate: 'onboarding/customer/stripeGuestCheckout/activate'
    },
    
  },
  
  activityAccess: {
    create: 8,
    read: 4,
    update: 2,
    delete: 1,
    all: 15,
    none: 0
  },

  activity: {
    entityList:2,
    complianceList: 3,
    eventList: 4,
    myTasks: 7,
    companyList: 6,
    renewals: 5,
    companyComplianceList: 7,
    cSuiteSummary: 11,
    performanceTracker: 12,
    taskTracker: 12,
    allTeamTasks: 13,
    auditorResources: 14,
    repoList: 16,
    userList: 19,
    roleList: 20
  },

  codeGroup: {
    userStatus: 100,
    companyType: 102,
    industryType: 103,
    actorType: 104,
    entityComplianceStatus: 106,
    approvalType: 107,
    dataType: 108,
    taskAction: 109,
    event: 110,
    oneTime:111,
    recurring: 112,
    regulatorType: 114,
    riskType: 115,
    reasonDoneWithException: 116,
    reasonCancelled: 117,
    taskStatus: 120,
    
  },
  scheduleType: {
    oneTime: 11101,
    eventBased: 11002
  },

  recurrenceType:{
    weekly: 11201,
    monthly: 11202,
    quarterly: 11203,
    halfYearly: 11204,
    yearly: 11205,
  },

  complianceStatus: {
    all: 113,
    draft: 11301,
    published: 11302
  },

  notificationStatus: {
    read: 11901,
    unRead: 11902
  },

  entityComplianceStatus: {
    draft: 10601,
    active: 10602,
    inactive: 10603
  },
  actorType: {
    maker: 10401,
    checker: 10402,
    approver: 10403,
    owner: 10404
  },
  approvalType: {
    notRequired: 10701,
    mandatory: 10702
  },
  dataType: {
    string: 10801,
    number: 10802,
    date: 10803,
    file: 10804,
    entityDocument: 10805
  },
  taskAction: {
    created: 10901,
    done: 10902,
    approved: 10903,
    rejected: 10904,
    canceled: 10905,
    doneWithException: 10906,
    reviewed: 10907,
    complied: 10908,
    compliedWithException: 10909,
    rescheduled: 10910
  },
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
  sliderTimeline: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,45,60,75,90,105,120,135,150,165,180,210,240,270,300,330],














  userStatus: {
    active: 10001,
    pending: 10002,
    deleted: 10003
  },


  productStatus: {
    active: 10501,
    inactive: 10502,
    upcoming: 10503
  },




  customerStatus: {
    active: 13001,
    pending: 13002,
    cancelledSubscription: 13003
  },



  productType: {
    physical: 15501,
    digital: 15502,
    affiliate: 15503
  },


  
  businessCategory: {
    yourStore: 12902
  },


  
  tagSuggestions: [
    { id: 1, name: 'Featured' }, 
    { id: 2, name: 'Popular' }, 
    { id: 3, name: 'Best seller'}, 
    { id: 4, name: 'Trending'}
  ],

  userTagSuggestions: [
    { id: 1, name: 'High spenders' }, 
    { id: 2, name: 'Cart abandoners' }, 
    { id: 3, name: 'Coupon lovers'}, 
    { id: 4, name: 'One timers'},
    { id: 5, name: 'Newsletter readers'},
    { id: 6, name: 'Indecisive shoppers'},
    { id: 7, name: 'Loyal customers'}
  ],

  

  regexExpressions: {
    ratings: /^[1-5](\.\d{1,2})?$/,
    numberOnly: /\d+$/,
    money: /^\d+(\.\d{1,2})?$/,
    //pan:/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
    pan:/^[a-zA-Z0-9]+$/,
    cin:/^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
    emailID: /^[^\s@]+@[^\s@]+\.[^\s@]{2,5}$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/,
    name: /^[a-zA-Z]+$/,
    hexRGB: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
    doubleSlash: /\/\//g,
    iframeUrl: /(src)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/, // /(?<=src=").*?(?=[\*"])/,// check work around for safari. - https://stackoverflow.com/questions/65918620/regex-is-working-on-chrome-but-not-in-safari
    domainName: /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/,
    indianPincode: /^\d{6}$/, ///  /^\d(6)$/,
    url:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
    brandName: /^[a-zA-Z\s0-9-_]+$/
  },
  
  country: {
    india: 101,
    usa: 233,       
  },
  

  elasticIndex : {
    productIndex: 'products_index',
    actIndex: 'act_index',
    complianceIndex: 'compliance_index',
  },
  
  httpHeaderKey: {
    responseAuthToken: 'x-auth-token',
    requestAuthToken: 'Authorization',
    requestAuthTokenPrefix: 'Bearer ',
    responseCustomerID: 'customerID',
    requestCustomerID: 'customerID',
  },

  cookieKey: {
    authToken: 'wjdcmuwihe',
    repoToken: 'sdqpuqldnn',
  },
  localStorageKey: {
    currentUser: 'n3jnriesnfnv',
    customerID: 'knsanlnfv',
    activityList: 'bhiqjpqugzol',
    prospectInfo: 'qosptofdu1a',
    
    listPageSearch: 'lspswenkpp',// datatype of data stored is object
    listPageSearchKey: {
      repoComplianceList: 'rco',
      selectableCompliance: 'scp',
      entityCompliance: 'ecp',
      leftMenuCompliance: 'lcp',
      entity:'ent',
      event: 'evt',
      pendingTasks: 'pen',
      allTasks: 'all'
      // product: 'prd',
      // category: 'cat',
      // customer: 'cus',
      // order: 'ord',
      // cart: 'car'
    }
  },

  messages: {
    httpDefaultError: 'common_unhandled_exception',
    userLoginError: 'user_login_error',
    userAuthTokenFailure: 'user_authentication_failure',
    noDataFound: ['user_list_no_matching_record_found', 'role_not_found',
      'product_list_no_matching_record_found', 'order_list_no_matching_record_found',
      'userProduct_list_no_matching_record_found', 'product_category_list_no_matching_record_found', 'discount_list_no_matching_record_found',
      'common_not_found']
  },

  toastMessage: {
    errorTimeout: 5000
  }

}

export default appConstants
